h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Raleway', sans-serif;
  font-weight: 900;
  text-transform: uppercase;
}
p,
ul {
  font-family: 'Yanone Kaffeesatz', sans-serif;
  font-size: 20px;
  font-weight: 400;
}
.navbar {
  background-color: #000204;
  min-height: 70px;
  padding-left: 40px;
  padding-right: 40px;
}
.navbar .navbar-brand {
  color: #ffffff;
}
.navbar .navbar-brand h4 {
  margin: 0;
}
.navbar .navbar-brand:hover {
  color: #ffffff;
}
.navbar .navbar-nav .nav-link {
  color: #ffffff;
  opacity: 0.5;
  font-weight: 500;
  transition: all 0.5s;
}
.navbar .navbar-nav .nav-link:hover {
  color: #ffffff;
  opacity: 1;
}
.navbar .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}
.mainContainer {
  padding-top: 40px;
  padding-bottom: 40px;
}
.homeContainer .featureImage {
  padding-top: 40px;
  max-width: 800px;
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
.pageTitleView {
  margin-bottom: 40px;
}
.pageTitleView h2 {
  font-size: 1.5em;
}
.aboutContainer .skills {
  margin-top: 40px;
}
@media only screen and (max-width: 575px) {
  .aboutContainer .skills {
    text-align: center;
  }
}
.postView .postRow {
  margin: 0 auto;
  max-width: 768px;
  margin-top: 50px;
  margin-bottom: 50px;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.postView .postRow .readMoreLink {
  display: block;
}
.postView .postImageHolder {
  max-width: 300px;
  margin: 0 auto;
}
@media only screen and (max-width: 992px) {
  .postView .postImageHolder {
    margin-bottom: 40px;
  }
}
.postView .postRowSeparator {
  margin-bottom: 10px;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  height: 1px;
  border-radius: 25%;
  background-color: #000204;
}
.musingContainer .featuredImage {
  max-width: 300px;
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
@media only screen and (max-width: 992px) {
  .musingContainer .featuredImage {
    margin-bottom: 40px;
  }
}
.musingContainer .contents {
  margin-top: 40px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
